import React from "react"
import { Container, Row, Col } from "react-bootstrap"

export default function intro({ goal, aboutTeam }) {
  return (
    <section id="intro" className="pt-5 pb-4">
      <Container className="d-none d-lg-block">
        <h2
          className="text-center text-bold text-primary "
          style={{ fontSize: "2.5rem" }}
        >
          Career Opportunities
        </h2>
        <Row>
          <Col md="12">
            <p style={{ lineHeight: "35px" }}>{goal}</p>
          </Col>
        </Row>
      </Container>
      <div className="d-block d-lg-none px-4">
        <h2
          className="text-center text-bold text-primary "
          style={{ fontSize: "2.5rem" }}
        >
          Career Opportunities
        </h2>
        <Row>
          <Col md="12">
            <p style={{ lineHeight: "35px" }}>{goal}</p>
          </Col>
        </Row>
      </div>
    </section>
  )
}
