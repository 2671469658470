import React from "react"

export default function PageHeader({
  bgImg,
  pgTitle,
  pgTitles,
  pgDesc,
  pgDesc2,
  showFullPageImage = false,
  objectPosition = "center center",
}) {
  const getPgTitle = () => {
    return (
      <>
        {" "}
        {pgTitles ? (
          pgTitles.map((x, i) => (
            <span
              key={i}
              className="text-primary text-left text-bold pb-2 m-0 d-block"
            >
              {x}
            </span>
          ))
        ) : (
          <span className="text-primary text-left text-bold pb-2 m-0 d-block">
            {pgTitle ? pgTitle : null}
          </span>
        )}
      </>
    )
  }

  return (
    <div className="bg-white">
      <div
        style={{
          height: "100vh",
        }}
        className="   d-none d-lg-block"
      >
        {showFullPageImage ? (
          <div
            // className="d-none"
            style={{
              backgroundImage: `url(${bgImg})`,

              backgroundPosition: "center",
              backgroundSize: "cover",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div className="  container d-none d-xl-block">
              <h1
                style={{ width: "40%", marginTop: "8vh" }}
                className="px-4 py-4 bg-light mb-0"
              >
                {getPgTitle()}
              </h1>
              <div
                style={{
                  width: "40%",
                }}
                className="px-4 py-4 bg-light my-2"
              >
                <p style={{ lineHeight: "1.5rem" }} className="mb-0">
                  {pgDesc}
                </p>
              </div>
            </div>

            <div className="  container d-none d-md-block d-xl-none">
              <h1 style={{ width: "50%" }} className="px-4 py-4 bg-light mb-0">
                {getPgTitle()}
              </h1>
              <div
                style={{
                  width: "50%",
                }}
                className="px-4 py-4 bg-light my-2"
              >
                <p style={{ lineHeight: "1.5rem" }} className="mb-0">
                  {pgDesc}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="row h-100 ">
            <div className="col-sm-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
                className="px-5"
              >
                <div>
                  <h1 style={{ marginTop: "8vh" }} className="px-4 mb-0">
                    {getPgTitle()}
                  </h1>{" "}
                  <div className="px-4 py-4 my-2">
                    <p style={{ lineHeight: "1.5rem" }} className="mb-4">
                      {pgDesc ? pgDesc : null}
                    </p>
                    <p style={{ lineHeight: "1.5rem" }} className="mb-0">
                      {pgDesc2 ? pgDesc2 : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div
                style={{
                  backgroundImage: `url(${bgImg})`,
                  backgroundColor: "black",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  position: "relative",
                  width: "100%",
                  height: "100%",
                }}
              ></div>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          height: "100vh",
        }}
        className="d-block d-lg-none bg-white"
      >
        <div className="h-100  ">
          <div style={{ height: "50%" }}>
            <img
              src={bgImg}
              alt={pgTitle}
              style={{
                objectFit: "cover",
                backgroundColor: "black",
                objectPosition: objectPosition,
                height: "100%",
                width: "100%",
              }}
            ></img>
          </div>{" "}
          <div className="h-50">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <h1 className="px-4 w-100 text-left mb-0">{getPgTitle()}</h1>{" "}
              <div className="px-4 w-100">
                <p
                  style={{ fontSize: "1.25rem", lineHeight: "35px" }}
                  className="d-none d-md-block"
                >
                  {pgDesc ? pgDesc : null}
                </p>
                <p
                  style={{ fontSize: "1rem", lineHeight: "35px" }}
                  className="mb-0 d-block d-md-none"
                >
                  {pgDesc ? pgDesc : null}
                </p>
                <p
                  style={{ fontSize: "1.25rem", lineHeight: "35px" }}
                  className="mb-0 d-none d-md-block"
                >
                  {pgDesc2 ? pgDesc2 : null}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pgDesc2 ? (
        <p
          style={{ fontSize: "1rem", lineHeight: "35px" }}
          className="d-block d-md-none px-4 mb-0"
        >
          {pgDesc2}
        </p>
      ) : null}
    </div>
  )
}
