import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WindMillImg from "../images/trial/team-min.jpg"
import PageHeader from "../partialPages/PageHeader"
import Intro from "../partialPages/careers/Intro"
import Jobs from "../partialPages/careers/Jobs"
import JobsData from "../data/jobsData.json"

const page = JobsData
const careers = () => (
  <Layout pageInfo={{ pageName: "careers" }} hideTimeline={true}>
    <SEO title={page.name} description={page.titleTxt} />

    <PageHeader
      bgImg={WindMillImg}
      pgTitle={page.name}
      pgDesc={page.aboutTeam}
      overlayOverRide={{
        background: "rgba(0,0,0,0.4)",
      }}
      pClass="text-left"
    ></PageHeader>
    <Intro goal={page.goal}></Intro>
    <Jobs jobList={page.jobs}></Jobs>
  </Layout>
)
export default careers
